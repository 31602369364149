import selectedBookingMixin from './selected-booking.mixin'

const data = {
  facilityRegisteredParkings: [],
  facilityRegisteredRentalItems: []
}

const computed = {
  hasParkings () {
    return this.facilityRegisteredParkings?.length > 0
  },
  hasRentalItems () {
    return this.facilityRegisteredRentalItems?.length > 0
  }
}

const watch = {
  async facility (facility) {
    if (!facility) return
    await this.$doLoading(async () => {
      this.facilityRegisteredParkings = await this.$store.dispatch('loadParkingsForBooking', {
        facilityId: facility.id,
        checkInDate: this.booking.checkInDate,
        checkOutDate: this.booking.checkOutDate
      })
    })

    await this.$doLoading(async () => {
      this.facilityRegisteredRentalItems = await this.$store.dispatch('loadRentalItemsForBooking', {
        facilityId: facility.id,
        checkInDate: this.booking.checkInDate,
        checkOutDate: this.booking.checkOutDate
      })
    })
    
  }
}

const {
  computed: orgComputed,
  watch: orgWatch,
  data: orgData,
  ...rest
} = selectedBookingMixin

export default {
  ...rest,
  data () {
    return {
      ...orgData?.(),
      ...data
    }
  },
  computed: {
    ...orgComputed,
    ...computed
  },
  watch: {
    ...orgWatch,
    ...watch
  }
}
